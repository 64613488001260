import { apiMixins } from "./api";

const DOWNLOAD_ENDPOINT = "item/download-folder";
const DOWNLOAD_SELECTED_ENDPOINT = "item/download-selected";

export const folderDownloadMixins = {
  mixins: [apiMixins],
  methods: {
    async downloadFolder(downloadFolder) {
      return await this.callAPI(DOWNLOAD_ENDPOINT, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ folderId: downloadFolder.id }),
      });
    },
    async downloadSelected(selectedItems, query) {
      return await this.callAPI(DOWNLOAD_SELECTED_ENDPOINT, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ selectedItems: selectedItems, query: query }),
      });
    },
  },
};
