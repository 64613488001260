<script>
import { notificationMixins } from "../../../mixins/notification";

export default {
  mixins: [notificationMixins],
  props: ["item", "sourceItem"],
  data() {
    return {
    };
  },
  methods: {
    selectedHandler(item) {
      this.$emit("selected", item);
    },
    canMove(item) {
      if (this.sourceItem.id === item.id) {
        return false;
      }
      // Can't move to a folder if you can't see what's in it
      if (!item.permissions.readAccess) {
        return false;
      }
      // Can't move to a folder if you can't write to it
      if (!item.permissions.writeAccess) {
        return false;
      }
      return true;
    }
  },
};
</script>

<template>
  <tbody>
    <tr v-if="canMove(item)" class="item" @click.prevent="selectedHandler(item)">
      <td class="icon">
        <m-icon icon="folder" />
      </td>
      <td class="name">
        <a href="#" @click.prevent.stop="selectedHandler(item)">{{ item.name }}</a>
      </td>
    </tr>
    <tr v-if="!canMove(item)" class="item">
      <td class="icon">
        <m-icon icon="folder_off" />
      </td>
      <td class="name">{{ item.name }}
      </td>
    </tr>
  </tbody>
</template>

<style lang="scss" scoped>
tr.item {
  cursor: pointer;

  .icon {
    width: 1rem;
    color: #4F4F4F;
  }
  .name {
  text-align: left;
  }

  td {
    font-size: 0.8rem;
  }

  div.tag-list {
    margin-top: 0.5rem;
  }
  div.tag-list span {
    font-size: 0.7rem;
    font-style: italic;
  }
}

</style>
