import { apiMixins } from "./api";

const PERMISSION_ENDPOINT = "item/permission";

export const permissionMixins = {
  mixins: [apiMixins],
  methods: {
    async getItemSharing(id) {
      return await this.callAPI(`${PERMISSION_ENDPOINT}/${id}`, { method: "GET" });
    },
    async setItemSharing(id, acl) {
      await this.callAPI(`${PERMISSION_ENDPOINT}/${id}`, { method: "PUT", headers: { "Content-Type": "application/json" }, body: JSON.stringify({ acl }) });
    },
  },
};
