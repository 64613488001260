<script>
import { permissionMixins } from "../../../mixins/permission";
import { notificationMixins } from "../../../mixins/notification";
import {userMixins} from "../../../mixins/user";
import Popup from "../../common/Popup.vue";
import {mapState} from "vuex";
import {fileMixins} from "../../../mixins/file";
import {folderMixins} from "../../../mixins/folder";

export default {
  mixins: [permissionMixins, fileMixins, folderMixins, notificationMixins, userMixins],
  props: {
    item: {
      type: Object,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    isFolder: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Popup,
  },
  computed: {
    ...mapState({
      isAdmin: (state) => state.auth.userData?.isAdmin ?? false,
    }),
  },
  data() {
    return {
      addTeamName: "",
      acl: [],
      newSteward: null,
      newOwner: null,
    };
  },
  mounted() {
    this.getItemSharing(this.item.id)
        .then(({ acl }) => this.acl = acl);
    this.newSteward = this.item.steward.name;
    this.newOwner = this.item.owner.name;
  },
  methods: {
    async shareConfirmed() {
      let itemChanges = {};
      if (this.newOwner && this.newOwner !== this.item.owner.name) {
        itemChanges.owner = this.newOwner;
      }

      if (this.newSteward && this.newSteward !== this.item.steward.name) {
        itemChanges.steward = this.newSteward;
      }

      if (Object.keys(itemChanges).length !== 0) {
        if (this.isFolder) {
          await this.updateFolder(this.item, itemChanges)
        } else {
          await this.updateFile(this.item, itemChanges)
        }
      }

      await this.setItemSharing(this.item.id, this.acl);
      this.showSuccess(this.$t("share-saved"));
      this.$emit("closed", true);
    },
    addTeam() {
      const name = this.addTeamName;
      if (!name) {
        return;
      }
      this.addTeamName = "";
      if (name === this.item.owner.name || name === this.item.steward.name) {
        return;
      }
      if (this.acl.find((u) => (u.name === name))) {
        return;
      }
      this.checkTeam({q: name}).then(() => {
        const newUser = {
          name,
          read: false,
          write: false,
          delete: false,
        };
        this.acl = [newUser, ...this.acl];
      })
    },
  }
};
</script>

<template>
  <Popup @cancelled="$emit('closed', false)" @confirmed="shareConfirmed" :icon="icon">
    <template v-slot:header>{{ item.name }}</template>
    <template v-slot:body>
      <form @submit.stop.prevent="addTeam" class="popup-section">
        <label class="entry-field">
          <span>{{ $t("share-add") }}</span>
          <input type="text" v-model="addTeamName" :placeholder="$t('share-add-placeholder')" />
          <button class="primary" @click="addTeam" :disabled="addTeamName.length===0">
            {{ $t("add-team-button") }}
          </button>
        </label>
      </form>
      <ul class="users popup-section">
        <li v-if="isAdmin || item.owner.includesMe || item.steward.includesMe" :class="{ me: item.owner.includesMe }">
          <label>{{ $t("share-folder-owner-placeholder") }}</label>
          <m-text-field v-model="newOwner" class="input" outlined required></m-text-field>
        </li>
        <li v-else :class="{ me: item.owner.includesMe }">
          <m-icon icon="person" />
          <span class="name">{{ item.owner.name }}</span>
          <span class="type">Owner</span>
        </li>
        <li v-if="isAdmin || item.steward.includesMe" :class="{ me: item.steward.includesMe }">
          <label>{{ $t("share-folder-steward-placeholder") }}</label>
          <m-text-field v-model="newSteward" class="input" outlined required>
          </m-text-field>
        </li>
        <li v-else :class="{ me: item.steward.includesMe }">
          <m-icon icon="person" />
          <span class="name">{{ item.steward.name }}</span>
          <span class="type">Steward</span>
        </li>
        <li v-for="user in acl" :key="user.name">
          <m-icon icon="person" />
          <span class="name">{{ user.name }}</span>
          <label class="permissions">
            <input type="checkbox" v-model="user.read"> {{ $t('share-permission-read') }}
          </label>
          <label class="permissions" v-if="isFolder">
            <input type="checkbox" v-model="user.write"> {{ $t('share-permission-write') }}
          </label>
          <label class="permissions">
            <input type="checkbox" v-model="user.delete"> {{ $t('share-permission-delete') }}
          </label>
        </li>
      </ul>
    </template>
  </Popup>
</template>

<style lang="scss" scoped>
.entry-field {
  display: flex;
  align-items: center;

  span {
    flex: 0 0 auto;
    font-weight: bold;
  }

  input {
    margin: -0.5rem 0 -0.5rem 0.5rem;
    padding: 0.5rem;
    flex: 1;
  }
  button {
    background: transparent;
    border: 1px solid #1275CC;
    color: #1275CC;
    border-radius: 4px;
    padding: 0.3rem 0.5rem;
    margin-left: 0.3rem;
    font-size: 0.9rem;
    font-weight: bold;
    cursor: pointer;
    line-height: 1.6rem;

    &:disabled {
      cursor: default;
      opacity: 0.2;
    }

    &.primary {
      background: #1275CC;
      border-color: transparent;
      color: white;
    }

    > i {
      vertical-align: -0.35rem;
      padding-right: 0.25rem;
    }
  }
}

.users {
  min-height: 14rem;
  max-height: 20rem;
  overflow-y: auto;
}

.users li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  &:last-child {
    margin-bottom: 0;
  }

  i {
    flex: 0 0 auto;
  }

  .name {
    flex: 1;
    margin: 0 0.5rem;
  }

  &.me .name::after {
    content: "(me)";
    color: #767676;
    margin-left: 0.5rem;
    font-size: 0.875em;
  }

  .type {
    flex: 0 0 auto;
    color: #767676;
  }

  .permissions {
    margin-left: 1rem;
    white-space: nowrap;
  }


}
</style>
