<script>
import { fileMixins } from "../../../mixins/file.js";
import { notificationMixins } from "../../../mixins/notification";
import Popup from "../../common/Popup.vue";
import MoveItemBrowser from "./MoveItemBrowser.vue";

export default {
  mixins: [fileMixins, notificationMixins],
  props: {
    item: {
      type: Object,
      required: true,
    },
    isParentFolderGroupRegister: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  components: {
    Popup, MoveItemBrowser
  },
  data() {
    return {
      isDeleting: false,
      isRenaming: false,
      isMoving: false,
      renameName: "",
      destinationFolder: null,
    };
  },
  methods: {
    deleteHandler() {
      this.isDeleting = true;
    },
    async deleteConfirmed() {
      this.isDeleting = false;
      await this.deleteFile(this.item);
      this.showSuccess(this.$t("operation-successful"));
      this.$emit("modified");
    },
    destinationFolderChanged(destinationFolder) {
      this.destinationFolder = destinationFolder;
    },
    moveHandler() {
      this.isMoving = true;
    },
    async moveConfirmed() {
      await this.moveFile(this.item, this.destinationFolder);
      this.showSuccess(this.$t("operation-successful"));
      this.$emit("modified");
    },
    renameHandler() {
      this.renameName = this.item.name;
      this.isRenaming = true;
    },
    async renameConfirmed() {
      this.isRenaming = false;
      if (this.renameName === this.item.name) {
        return;
      }
      await this.renameFile(this.item, this.renameName);
      this.showSuccess(this.$t("item-renamed"));
      this.$emit("modified");
    },
    toFileProperties(){
       this.$router.push({ path: "/properties/" + this.item.id,   params: { item: this.item }}
      );
    }
  }
};
</script>

<template>
  <div class="options">
    <button v-if="item.permissions.writeAccess" @click.prevent="toFileProperties">{{ $t("file-properties") }}</button>
    <button v-if="!item.suspended && item.permissions.deleteAccess && !item.trashRepository && item.parentId && !isParentFolderGroupRegister" @click.prevent="moveHandler">{{ $t("move") }}</button>
    <button v-if="!item.suspended && item.permissions.writeAccess && !item.trashRepository" @click.prevent="renameHandler">{{ $t("rename") }}</button>
    <button v-if="!item.suspended && item.permissions.deleteAccess && !item.trashRepository" @click.prevent="deleteHandler" :disabled="!!item.suspended">{{ $t("delete") }}</button>
    
    <Popup v-if="isMoving" @cancelled="isMoving = false" @confirmed="moveConfirmed" :defaultButtons="false">
      <template v-slot:header>{{ $t("move-file-title", { name: item.name }) }}</template>
      <template v-slot:body>
        <MoveItemBrowser :itemToMove="item" @destinationFolderChanged="destinationFolderChanged"/>
      </template>
      <template v-slot:footer>
        <button type="button" @click.stop="isMoving = false">{{ $t("cancel") }}</button>
        <button type="button" class="primary" @click.stop="moveConfirmed">{{ $t("move") }}</button>
      </template>
    </Popup>
    
    <Popup v-if="isRenaming" :valid="renameName.length >= 2" @cancelled="isRenaming = false" @confirmed="renameConfirmed">
      <template v-slot:header>{{ $t("rename-file-title") }}</template>
      <template v-slot:body>
        <m-text-field v-model="renameName" outlined required>
          <m-floating-label>{{ $t("rename-file-name-placeholder") }}</m-floating-label>
        </m-text-field>
      </template>
    </Popup>

    <Popup v-if="isDeleting" @cancelled="isDeleting = false" @confirmed="deleteConfirmed">
      <template v-slot:header>{{ $t('delete-file-title') }}</template>
      <template v-slot:body>{{ $t('delete-file-message', { item: item.name }) }}</template>
    </Popup>
  </div>
</template>

<style lang="scss" scoped>
.options {
  text-align: right;
}

button {
  margin: -0.5rem 0 -0.5rem 1rem;
  cursor: pointer;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
}

footer button {
  background: transparent;
  border: 1px solid #1275CC;
  color: #1275CC;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-weight: bold;
  cursor: pointer;
  line-height: 1.6rem;

  &:disabled {
    cursor: default;
    opacity: 0.2;
  }

  &.primary {
    background: #1275CC;
    border-color: transparent;
    color: white;
  }

  > i {
    vertical-align: -0.35rem;
    padding-right: 0.25rem;
  }
}
</style>
