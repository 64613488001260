<script>
import MoveItemBrowserItem from "./MoveItemBrowserItem.vue";
import { folderMixins } from "../../../mixins/folder";
import Pagination from "../../common/Pagination";

export default {
  mixins: [ folderMixins ],
  components: {
    Pagination,
    MoveItemBrowserItem,
  },
  emits: ["destinationFolderChanged"],
  props: ["itemToMove"],
  data() {
    return {
      page: 0,
      pageSize: 10,
      currentSort: "label",
      currentSortDir: "asc",
      targetFolderId: this.itemToMove.parentId,
    };
  },
  asyncComputed: {
    async currentFolder() {
      return await this.getFolder(this.targetFolderId, {
        page: this.page,
        pageSize: this.pageSize,
        sort: this.currentSort + "," + this.currentSortDir,
        showLoader: false,
        onlyFolders: true,
      })
      .then(response => {
        this.$emit("destinationFolderChanged", response.self);
        return response;
      });
    },
  },
  computed: {
    pages() {
      if (!this.currentFolder) {
        return 0;
      }
      return Math.ceil(this.currentFolder.totalItemCount / this.pageSize);
    },
    sortIcon() {
      if (this.currentSortDir === "asc") {
        return "arrow_upward";
      } else return "arrow_downward";
    },
  },
  methods: {
    refresh() {
      this.currentFolder.update();
    },
    setPage(i) {
      this.page = i;
    },
    setPageSize(newPageSize) {
      this.pageSize = newPageSize;
    },
    setFilter(newFilter) {
      this.page = 0;
      this.filter = newFilter;
    },
    folderSelected(item) {
      this.targetFolderId = item.id;
      this.$emit("destinationFolderChanged", item);
    },
    goUpOne() {
      this.targetFolderId = this.currentFolder.self.parentId;
      //this.$emit("destinationFolderChanged", this.currentFolder.self);
    },
    hasRecords() {
      return this.currentFolder && this.currentFolder.items && this.currentFolder.items.length;
    },
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        this.page = 0;
      }
    },
  },
};
</script>

<template>
  <div class="browser">
    <p v-if="$asyncComputed.currentFolder.updating" class="loading">Loading</p>
    <p v-else-if="$asyncComputed.currentFolder.error" class="load-error">
      Failed to load folder contents
    </p>
    <div v-else>
    <p class="description">{{ $t("move-folder-destination", { name: this.currentFolder.self.name }) }}</p>
    <table class="listing">
      <tbody class="back">
          <td v-if="currentFolder.self.parentId" colspan="2" @click="goUpOne">
              <m-icon icon="arrow_upward" />
          </td>
          <td v-if="!currentFolder.self.parentId" colspan="2">
              <m-icon icon="arrow_upward" />
          </td>
      </tbody>
      <template v-for="item in currentFolder.items">
        <MoveItemBrowserItem
          :key="item.id"
          :item="item"
          :sourceItem="itemToMove"
          @modified="refresh"
          @selected="folderSelected(item)"
        />
      </template>
    </table>
    <Pagination
      :page="page"
      :pages="pages"
      :pageSize="pageSize"
      :totalRecords="currentFolder.totalItemCount"
      :condensed="true"
      @pageChange="setPage"
      @pageSizeChange="setPageSize"
    />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.loading,
.load-error {
  text-align: center;
  margin-top: 2rem;
}
input.filter {
  flex: 1 1 auto;
  background: #f9f9f9;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  color: black;
  padding: 0.5rem 1rem;
}
.sort-icon {
  font-size: 14px;
}
.listing {
  width: 100%;
  font-size: 0.8rem;

  th {
    font-weight: bold;
    color: #5b5b5b;
  }

  th.icon,
  th.actions,
  th.options {
    width: 1px;
  }

  .expires-on {
    width: 6rem;
  }
  .created-on {
    //padding-left: 2rem;
    width: 10rem;
  }
  .size {
    width: 4rem;
  }
  .options {
    text-align: center;
  }

  ::v-deep tbody {
    background: white;

    td {
      border-bottom: 1px solid #ebebeb;
      height: 2rem;
    }
  }

  th,
  ::v-deep td {
    padding: 1rem 0.75rem;

    &:first-child {
      padding-left: 1rem;
    }
    &:last-child {
      padding-right: 1.5rem;
    }
  }
}

.back {
  cursor: pointer;

  &:hover {
    background: #eeeeee;
  }
}

p.description {
  margin-bottom: 1.25rem;
}
</style>
