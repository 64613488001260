<script>
import { folderDownloadMixins } from "../../../mixins/folderdownload.js";
import { notificationMixins } from "../../../mixins/notification";
import FolderOperations from "./FolderOperations.vue";
import ItemSharing from "../sharing-popup/ItemSharing.vue";
import {formatISODate} from "@/util/date";
import Popup from "../../common/Popup.vue";

export default {
  mixins: [folderDownloadMixins, notificationMixins],
  props: ["item"],
  components: {
    FolderOperations,
    ItemSharing,
    Popup
  },
  data() {
    return {
      isOptionsVisible: false,
      isSharing: false,
      isDownloading: false,
      isDownloaded: false,
      downloadFolderResults: null
    };
  },
  methods: {
    toggleOptions() {
      this.isOptionsVisible = !this.isOptionsVisible;
    },
    sharingClosed(changed) {
      this.isSharing = false;
      if (changed) {
        this.$emit('modified');
      }
    },
    formatCreatedOnDate(createdOnISODate){
      return formatISODate(createdOnISODate);
    },
    downloadHandler() {
      this.isDownloading = true;
    },
    async downloadConfirmed() {
      this.isDownloading = false;
      let results = await this.downloadFolder(this.item);
      this.downloadFolderResults = results.downloadFolder;
      console.log(this.downloadFolderResults);
    },
    downloadQueueClosed() {
      this.isDownloaded = false;
      this.isDownloading = false;
      this.downloadFolderResults = null;
    },
    formatFileSizeInMegabytes(fileSizeInBytes) {
      let fileSizeInMegabytes = fileSizeInBytes / 1024 / 1024;
      return fileSizeInMegabytes.toLocaleString();
    }
  },
};
</script>

<template>
  <tbody>
    <tr class="item" @click.prevent="$emit('selected')" :class="{ opened: isOptionsVisible }">
      <td class="icon">
        <m-icon icon="folder" v-if="!item.groupRegister" />
        <m-icon icon="folder_special" v-if="item.groupRegister"/>
      </td>
      <td class="name">
        <a href="#" @click.prevent.stop="$emit('selected')">{{ item.name }}</a>
          <div class="tag-list" v-if="item.tags && item.tags.length">
            <span>{{ item.tags.join(", ") }}</span>
          </div>
      </td>
      <td>{{ formatCreatedOnDate(item.created) }}</td>
      <td class="expires-on">-</td>
      <td>-</td>
      <td class="actions">
        <span class="suspended-status" v-if="item.suspended">{{ $t("suspended") }}</span>
        <button v-if="item.permissions.writeAccess && !item.trashRepository" @click.stop="isSharing = true">{{ $t("share") }}</button>
        <button v-if="item.permissions.readAccess" @click.stop="downloadHandler">{{ $t("download") }}</button>
        <ItemSharing v-if="isSharing" :item="item" icon="folder" is-folder @closed="sharingClosed" />
      </td>
      <td class="options" @click.stop="toggleOptions">
        <a v-if="item.permissions.writeAccess" href="#" @click.prevent>
          <m-icon icon="settings" />
        </a>
      </td>
    </tr>
    <tr class="options-bar" v-if="isOptionsVisible">
      <td colspan="8">
        <FolderOperations :item="item" @modified="$emit('modified')" />
      </td>
    </tr>
    <Popup v-if="isDownloading" @cancelled="isDownloading = false" @confirmed="downloadConfirmed">
      <template v-slot:header>{{ $t('download-folder-title') }}</template>
      <template v-slot:body>{{ $t('download-folder-message', { item: item.name }) }}</template>
    </Popup>
    <Popup v-if="downloadFolderResults" @confirmed="downloadQueueClosed" :allowCancel="false">
      <template v-slot:header>{{ $t("download-queued-folder-title") }}</template>
      <template v-slot:body>
        <p>{{ $t("download-queued-folder-message")}}</p>
        <br/>
        <ul>
          <li>{{ $t("download-queued-folder-number-of-files", { numberOfFiles: downloadFolderResults.numberOfFiles.toLocaleString() }) }}</li>
          <li>{{ $t("download-queued-folder-total-size", { totalSize: formatFileSizeInMegabytes(downloadFolderResults.downloadSize) }) }}</li>
          <li>{{ $t("download-queued-folder-number-of-files-excluded", { numberOfFilesExcluded: downloadFolderResults.numberOfFilesNotAdded }) }}</li>
        </ul>
      </template>
    </Popup>
  </tbody>
</template>

<style lang="scss" scoped>
tr.item {
  cursor: pointer;

  .actions {
    text-align: right;
    white-space: nowrap;

    .suspended-status {
      margin: -0.5rem 0 -0.5rem 1rem;
      font-size: 0.875rem;
      padding: 0.5rem 1rem;
      background: #F09F26;
    }
  }

  .actions > * {
    visibility: hidden;
  }

  &:hover, &.opened {
    background: #EEEEEE;

    .actions > * {
      visibility: visible;
    }

    .options {
      color: #4F4F4F;
    }
  }

  .icon {
    color: #4F4F4F;
  }

  .options {
    color: #CACACA;

    &:hover {
      color: #4F4F4F;
    }
    text-align: center;
  }

  td {
    font-size: 0.8rem;
  }

  div.tag-list {
    margin-top: 0.5rem;
  }
  div.tag-list span {
    font-size: 0.7rem;
    font-style: italic;
  }
}

button {
  margin: -0.5rem 0 -0.5rem 1rem;
  cursor: pointer;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  background: #DCDCDC;
}

tr.options-bar {
  background: #EBEBEB;
}

</style>
