<script>
import { folderMixins } from "../../../mixins/folder.js";
import { notificationMixins } from "../../../mixins/notification";
import Popup from "../../common/Popup.vue";
import MoveItemBrowser from "./MoveItemBrowser.vue";

export default {
  mixins: [folderMixins, notificationMixins],
  props: {
    item: {
      type: Object,
      required: true,
    },
    isParentFolderGroupRegister: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    Popup,
    MoveItemBrowser,
  },
  data() {
    return {
      isDeleting: false,
      isMoving: false,
      isRenaming: false,
      renameName: "",
      additionalFolderProperties: {
        totalSize: 0,
        numberOfFiles: 0,
        numberOfNonExpiredFiles: 0,
        numberOfSuspendedFiles: 0,
      },
      isGroupRegisterFolder: false,
      groupRegisterPrefix: "",
      groupRegisterSuffix: "",
      destinationFolder: null,
    };
  },
  methods: {
    async deleteHandler() {
      this.isDeleting = true;
      await this.getAdditionalFolderProperties();
    },
    async deleteConfirmed() {
      this.isDeleting = false;
      await this.deleteFolder(this.item);
      this.showSuccess(this.$t("operation-successful"));
      this.$emit("modified");
    },
    destinationFolderChanged(destinationFolder) {
      this.destinationFolder = destinationFolder;
    },
    moveHandler() {
      this.isMoving = true;
    },
    async moveConfirmed() {
      await this.moveFolder(this.item, this.destinationFolder);
      this.showSuccess(this.$t("operation-successful"));
      this.$emit("modified");
    },
    renameHandler() {
      this.renameName = this.item.name;
      if (this.isParentFolderGroupRegister) {
        const groupRegisterFolderRegex = /^([0-9]{1,10})\s?(.*)$/
        const match = this.item.name.match(groupRegisterFolderRegex);
        if (match) {
          this.groupRegisterPrefix = match[1];
          this.groupRegisterSuffix = match[2];
          this.isGroupRegisterFolder = true;
        }
      }
      this.isRenaming = true;
    },
    async renameConfirmed() {
      this.isRenaming = false;
      if (!this.isGroupRegisterFolder && this.renameName === this.item.name) {
        return;
      }
      if (this.isGroupRegisterFolder && this.groupRegisterRenameName === this.item.name) {
        return;
      }
      await this.renameFolder(this.item, this.isGroupRegisterFolder ? this.groupRegisterRenameName : this.renameName);
      this.showSuccess(this.$t("item-renamed"));
      this.$emit("modified");
    },
    async getAdditionalFolderProperties() {
      this.additionalFolderProperties = await this.getRecursiveFolderProperties(this.item.id);
    },
    async toFolderProperties(){
      await this.getAdditionalFolderProperties();
      this.$router.push({ name: "folder-properties", params: { item: this.item, additionalFolderProperties: this.additionalFolderProperties } });
    }
  },
  computed: {
    groupRegisterRenameName() {
      return `${this.groupRegisterPrefix} ${this.groupRegisterSuffix}`;
    }
  }
};
</script>

<template>
  <div class="options">
    <button @click.prevent="toFolderProperties">{{ $t("folder-properties") }}</button>
    <button v-if="!item.suspended && item.permissions.deleteAccess && !item.trashRepository && item.parentId && !isParentFolderGroupRegister" @click.prevent="moveHandler">{{ $t("move") }}</button>
    <button v-if="!item.suspended && item.permissions.writeAccess && !item.trashRepository" @click.prevent="renameHandler">{{ $t("rename") }}</button>
    <button v-if="!item.suspended && item.permissions.deleteAccess && !item.trashRepository" @click.prevent="deleteHandler">{{ $t("delete") }}</button>

    <Popup v-if="isMoving" @cancelled="isMoving = false" @confirmed="moveConfirmed" :defaultButtons="false">
      <template v-slot:header>{{ $t("move-folder-title", { name: item.name }) }}</template>
      <template v-slot:body>
        <MoveItemBrowser :itemToMove="item" @destinationFolderChanged="destinationFolderChanged"/>
      </template>
      <template v-slot:footer>
        <button type="button" @click.stop="isMoving = false">{{ $t("cancel") }}</button>
        <button type="button" class="primary" @click.stop="moveConfirmed">{{ $t("move") }}</button>
      </template>
    </Popup>
    
    <Popup v-if="isRenaming && (!isParentFolderGroupRegister || !isGroupRegisterFolder)" :valid="renameName.length >= 2" @cancelled="isRenaming = false" @confirmed="renameConfirmed">
      <template v-slot:header>{{ $t("rename-folder-title") }}</template>
      <template v-slot:body>
        <m-text-field v-model="renameName" outlined required>
          <m-floating-label>{{ $t("rename-folder-name-placeholder") }}</m-floating-label>
        </m-text-field>
      </template>
    </Popup>

    <Popup v-if="isRenaming && isParentFolderGroupRegister && isGroupRegisterFolder" :valid="groupRegisterSuffix.length >= 2" @cancelled="isRenaming = false" @confirmed="renameConfirmed">
      <template v-slot:header>{{ $t("rename-folder-title") }}</template>
      <template v-slot:body>
        <m-text-field v-model="groupRegisterPrefix" outlined required disabled>
          <m-floating-label>Prefix</m-floating-label>
        </m-text-field>
        <m-text-field v-model="groupRegisterSuffix" outlined required>
          <m-floating-label>New suffix</m-floating-label>
        </m-text-field>
      </template>
    </Popup>

    <Popup v-if="isDeleting" @cancelled="isDeleting = false" @confirmed="deleteConfirmed">
      <template v-slot:header>{{ $t('delete-folder-title') }}</template>
      <template v-slot:body>
        <ul class="delete-list">
          <li>{{ $t('delete-folder-message', { item: item.name }) }}</li>
          <li v-if="additionalFolderProperties && additionalFolderProperties.numberOfNonExpiredFiles">{{ $t('delete-folder-message-non-expired-items', 
          { item: additionalFolderProperties.numberOfNonExpiredFiles}) }}</li>
          <li v-if="additionalFolderProperties && additionalFolderProperties.numberOfSuspendedFiles">{{ $t('delete-folder-message-suspended-items') }}</li>
          </ul>
        
        </template>
    </Popup>
  </div>
</template>

<style lang="scss" scoped>
.options {
  text-align: right;
}

button {
  margin: -0.5rem 0 -0.5rem 1rem;
  cursor: pointer;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
}

.delete-list > li {
  list-style: disc;
  margin-bottom: 1.5em;
  margin-left: 0.5em;
}

.deletion-message {
  margin-bottom: 1.5em;
}

.deletion-message-emphasis {
  font-weight: bold;
}

footer button {
  background: transparent;
  border: 1px solid #1275CC;
  color: #1275CC;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-weight: bold;
  cursor: pointer;
  line-height: 1.6rem;

  &:disabled {
    cursor: default;
    opacity: 0.2;
  }

  &.primary {
    background: #1275CC;
    border-color: transparent;
    color: white;
  }

  > i {
    vertical-align: -0.35rem;
    padding-right: 0.25rem;
  }
}
</style>
