export function prettyPrintFileSize(size) {
    const units = ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    let num = parseInt(size);
    if (Number.isNaN(num)) {
        throw new TypeError("Expected a number");
    }

    const prefix = (num < 0) ? "-" : "";
    num = Math.abs(num);

    if (num < 1) {
        return `0 ${units[0]}`;
    }

    const exponent = Math.min(
        Math.floor(Math.log(num) / Math.log(1000)),
        units.length - 1
    );
    const shortNum = (num / Math.pow(1000, exponent))
        .toFixed(2)
        .replace(/\.?0*$/, ''); // remove trailing 0s after the decimal point

    return `${prefix}${shortNum} ${units[exponent]}`;
}